import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Gallery from '../components/Gallery';

// markup
const Saadetud = () => {
  const data = useStaticQuery(graphql`
  query { 
    file(sourceInstanceName: {eq: "static"}, name: {eq: "kutsetunnistus"}) {
    dir
    childImageSharp {
      gatsbyImageData(height: 450, quality: 80)
      id
      original {
        src
      }
    }
  }
  }
`);

  const imgs = data
    && data.file
    && [data.file.childImageSharp];

  return (
    <Layout pageTitle="Saadetud">
      <section className="section">
        <div className="container has-text-centered">
          <h1 className="is-size-2">
            Saadetud
          </h1>
          <h1 className="is-size-4 mt-5">
            Vastame teile esimesel võimalusel!
          </h1>
        </div>
      </section>
    </Layout>
  );
};

export default Saadetud;
